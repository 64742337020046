import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import { TESTIMONIALS_VIDEOS_LIST_CONTENT } from "./index.content";
import { getTrueClasses } from "../../../helpers";
import WrapSlider from "../WrapSlider";
import "./index.scss";

export const TestimonialsVideos = (props) => {
  const { setUrlYoutubeModal, isMainPage, customClass, customContent } = props;
  const {
    media: { isNotInitMobile, isTablet, isSmallDesktop, isDesktop },
  } = useContext(MediaAuthContext);

  const [coordStartClick, setCoordStartClick] = useState({ x: 0, y: 0 });
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const getSlidesCount = (mob, tab, lap, desc) => {
    switch (true) {
      case mob:
        return 1;
      case tab:
        return 2;
      case lap:
        return 3;
      case desc:
        return 4;
    }
  };
  const numberOfSlides = getSlidesCount(
    isNotInitMobile,
    isTablet,
    isSmallDesktop,
    isDesktop
  );

  const settingsMob = {
    className: "TestimonialsVideos-slider",
    centerMode: true,
    dots: false,
    infinite: false,
    initialSlide: 0,
    speed: 500,
    touchThreshold: 40,
    variableWidth: true,
  };

  const settings = {
    className: "TestimonialsVideos-slider",
    dots: true,
    speed: 500,
    infinite: false,
    slidesToShow: numberOfSlides,
    slidesToScroll: numberOfSlides,
    variableWidth: true,
    nextArrow: <NextButton />,
    prevArrow: <PrevButton />,
    ...(isNotInitMobile && settingsMob),
  };

  const handleMouseDown = (e) => {
    setCoordStartClick({
      x: e.clientX,
      y: e.clientY,
    });
  };

  const handleClickVideo = (e, url) => {
    const isSwipe =
      Math.abs(coordStartClick.x - e.clientX) > 3 ||
      Math.abs(coordStartClick.y - e.clientY) > 3;
    if (!isSwipe) {
      setUrlYoutubeModal(url);
    }
  };

  return (
    <section
      className={getTrueClasses(
        "TestimonialsVideos",
        isMainPage && "TestimonialsVideos_mainPage",
        customClass
      )}
    >
      <div className="container">
        {isRendered ? (
          <WrapSlider settings={settings}>
            {(customContent ?? TESTIMONIALS_VIDEOS_LIST_CONTENT).map(
              ({ JPG_2X, url, name, position, text }, index) => (
                <div
                  className="TestimonialsVideos-item"
                  key={name || index}
                  onMouseDown={handleMouseDown}
                  onMouseUp={(e) => handleClickVideo(e, url)}
                >
                  <div className="TestimonialsVideos-item__img">
                    <div className="play-btn" />
                    <img src={JPG_2X} alt="" loading="lazy" />
                  </div>
                  <div className="TestimonialsVideos-item__name">{name}</div>
                  <div className="TestimonialsVideos-item__position">
                    {position}
                  </div>
                  <div className="TestimonialsVideos-item__text">{text}</div>
                </div>
              )
            )}
          </WrapSlider>
        ) : null}
      </div>
    </section>
  );
};

TestimonialsVideos.propTypes = {
  setUrlYoutubeModal: PropTypes.func,
};

export default TestimonialsVideos;

const NextButton = (props) => {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
};
const PrevButton = (props) => {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style }} onClick={onClick} />;
};
