import React from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./index.scss";

const WrapSlider = ({ settings, children }) => (
  <Slider {...settings}>{children}</Slider>
);

WrapSlider.propTypes = {
  children: PropTypes.any.isRequired,
  settings: PropTypes.object,
};

export default WrapSlider;
