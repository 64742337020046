// import JPG_ANDREW from "../../../assets/images/raster/components/TestimonialsVideos/andrew.jpg";
import JPG_2X_ANDREW from "../../../assets/images/raster/components/TestimonialsVideos/andrew@2x.jpg";
// import JPG_ANDY from "../../../assets/images/raster/components/TestimonialsVideos/andy.jpg";
import JPG_2X_ANDY from "../../../assets/images/raster/components/TestimonialsVideos/andy@2x.jpg";
// import JPG_CARLOS from "../../../assets/images/raster/components/TestimonialsVideos/carlos.jpg";
import JPG_2X_CARLOS from "../../../assets/images/raster/components/TestimonialsVideos/carlos@2x.jpg";
// import JPG_CRESCENT from "../../../assets/images/raster/components/TestimonialsVideos/crescent.jpg";
import JPG_2X_CRESCENT from "../../../assets/images/raster/components/TestimonialsVideos/crescent@2x.jpg";
// import JPG_CRISTIAN from "../../../assets/images/raster/components/TestimonialsVideos/cristian.jpg";
import JPG_2X_CRISTIAN from "../../../assets/images/raster/components/TestimonialsVideos/cristian@2x.jpg";
// import JPG_DAN from "../../../assets/images/raster/components/TestimonialsVideos/dan.jpg";
import JPG_2X_DAN from "../../../assets/images/raster/components/TestimonialsVideos/dan@2x.jpg";
// import JPG_EVEN from "../../../assets/images/raster/components/TestimonialsVideos/even.jpg";
import JPG_2X_EVEN from "../../../assets/images/raster/components/TestimonialsVideos/even@2x.jpg";
// import JPG_PAUL from "../../../assets/images/raster/components/TestimonialsVideos/paul.jpg";
import JPG_2X_PAUL from "../../../assets/images/raster/components/TestimonialsVideos/paul@2x.jpg";
// import JPG_RECO from "../../../assets/images/raster/components/TestimonialsVideos/reco.jpg";
import JPG_2X_RECO from "../../../assets/images/raster/components/TestimonialsVideos/reco@2x.jpg";
// import JPG_RILEY from "../../../assets/images/raster/components/TestimonialsVideos/riley.jpg";
import JPG_2X_RILEY from "../../../assets/images/raster/components/TestimonialsVideos/riley@2x.jpg";
// import JPG_RYAN from "../../../assets/images/raster/components/TestimonialsVideos/ryan.jpg";
import JPG_2X_RYAN from "../../../assets/images/raster/components/TestimonialsVideos/ryan@2x.jpg";
// import JPG_SAMI from "../../../assets/images/raster/components/TestimonialsVideos/sami.jpg";
import JPG_2X_SAMI from "../../../assets/images/raster/components/TestimonialsVideos/sami@2x.jpg";
// import JPG_TANNER from "../../../assets/images/raster/components/TestimonialsVideos/tanner.jpg";
import JPG_2X_TANNER from "../../../assets/images/raster/components/TestimonialsVideos/tanner@2x.jpg";
// import JPG_WILLIAM from "../../../assets/images/raster/components/TestimonialsVideos/william.jpg";
import JPG_2X_WILLIAM from "../../../assets/images/raster/components/TestimonialsVideos/william@2x.jpg";
// import JPG_TRAVIS from "../../../assets/images/raster/components/TestimonialsVideos/travis.jpg";
import JPG_2X_TRAVIS from "../../../assets/images/raster/components/TestimonialsVideos/travis@2x.jpg";
// import JPG_STEVE from "../../../assets/images/raster/components/TestimonialsVideos/steve.jpg";
import JPG_2X_STEVE from "../../../assets/images/raster/components/TestimonialsVideos/steve@2x.jpg";
// import JPG_LUKE from "../../../assets/images/raster/components/TestimonialsVideos/luke.jpg";
import JPG_2X_LUKE from "../../../assets/images/raster/components/TestimonialsVideos/luke@2x.jpg";
// import JPG_KELLY from "../../../assets/images/raster/components/TestimonialsVideos/kelly.jpg";
import JPG_2X_KELLY from "../../../assets/images/raster/components/TestimonialsVideos/kelly@2x.jpg";
// import JPG_JAMIE from "../../../assets/images/raster/components/TestimonialsVideos/jamie.jpg";
import JPG_2X_JAMIE from "../../../assets/images/raster/components/TestimonialsVideos/jamie@2x.jpg";
// import JPG_KRYSTEL from "../../../assets/images/raster/components/TestimonialsVideos/krystel.jpg";
import JPG_2X_KRYSTEL from "../../../assets/images/raster/components/TestimonialsVideos/krystel@2x.jpg";


const TRAVIS = {
  // JPG: JPG_TRAVIS,
  JPG_2X: JPG_2X_TRAVIS,
  url: "https://www.youtube.com/embed/CKkB3V8YG74?autoplay=1&modestbranding=1",
  name: "Travis Marziani",
  position: "Amazon seven-figure seller and YouTube blogger with over 350K subscribers",
  text: "“AMZScout is a great tool to help you find the product.”",
};
const STEVE = {
  // JPG: JPG_STEVE,
  JPG_2X: JPG_2X_STEVE,
  url: "https://www.youtube.com/embed/P2v3oi7zwMY?autoplay=1&modestbranding=1",
  name: "Steve Chou (My Wife Quit Her Job)",
  position: "Made over $100K in profit during his and his wife's first year in business",
  text: "“So, what AMZScout has a Database that you can use to speedback products that meet your specifications.”",
};
const DAN = {
  // JPG: JPG_DAN,
  JPG_2X: JPG_2X_DAN,
  url: "https://www.youtube.com/embed/49v1HOyMHAw?autoplay=1&modestbranding=1",
  name: "Dan Rodgers",
  position: "Expert coach & content creator",
  text: "“This FBA toolkit is great! There’s a lot of hidden features that have really been helpful during my process, and I think it will help save you time during research.”",
};
const WILLIAM = {
  // JPG: JPG_WILLIAM,
  JPG_2X: JPG_2X_WILLIAM,
  url: "https://www.youtube.com/embed/JcmJ4LRQQTw?autoplay=1&modestbranding=1",
  name: "William Rivera",
  position: "Founder of Ecom Degree University & Amazon FBA giant",
  text: "“Don't sleep on this tool. I wanted to make sure that you take action, get this, implement this into your business because that's what I’ve been doing.”",
};
const LUKE = {
  // JPG: JPG_LUKE,
  JPG_2X: JPG_2X_LUKE,
  url: "https://www.youtube.com/embed/k8LZVII3R7g?autoplay=1&modestbranding=1",
  name: "Luke Wechselberger",
  position: "Successful Amazon seller & YouTube blogger",
  text: "“The product research software I use is AMZScout - this one happens to have pretty much the best price for what you get. All the tools, like the Product Database which helps you find potential products to sell, the Chrome Extension, the keyword research - everything here is included in the bundle.”",
};
const RECO = {
  // JPG: JPG_RECO,
  JPG_2X: JPG_2X_RECO,
  url: "https://www.youtube.com/embed/UhZ8s3zgZnM?autoplay=1&modestbranding=1",
  name: "Reco Jefferson",
  position: "7-figure seller & Roughnecks eCom bootcamp instructor",
  text: "“My favorite software tool of all time. It's helped me make so much freaking money. I don't know where I would be without this tool.”",
};
const KELLY = {
  // JPG: JPG_KELLY,
  JPG_2X: JPG_2X_KELLY,
  url: "https://www.youtube.com/embed/OMjE4QfOrQc?autoplay=1&modestbranding=1",
  name: "Kelly Colman",
  position: "Award winning Amazon FBA seller & coach",
  text: "“AMZScout is my product of choice, especially when you’re a beginner, because it has amazing tools.”",
};
const JAMIE = {
  // JPG: JPG_JAMIE,
  JPG_2X: JPG_2X_JAMIE,
  url: "https://www.youtube.com/embed/ExYvdFL3W6w?autoplay=1&modestbranding=1",
  name: "Jamie Davidson",
  position: "9-figure Amazon seller & founder of AMZ Insiders",
  text: "“If you also want to use it in your research to find great products to sell, because you want to know what the demand level is and how many times people are searching for that, AMZScout is a game changer.”",
};
const RYAN = {
  // JPG: JPG_RYAN,
  JPG_2X: JPG_2X_RYAN,
  url: "https://www.youtube.com/embed/-I38muHMEpk?autoplay=1&modestbranding=1",
  name: "Ryan Hogue",
  position: "7-figure Amazon seller & 6-figure print on demand seller",
  text: "“Now this might actually be the easiest tool to use to find new niches that can be profitable for your FBA business.”",
};
const CRESCENT = {
  // JPG: JPG_CRESCENT,
  JPG_2X: JPG_2X_CRESCENT,
  url: "https://www.youtube.com/embed/S-cS9Ki6ToQ?autoplay=1&modestbranding=1",
  name: "Crescent Kao",
  position: "Amazon FBA private label master",
  text: "“Using the AMZScout Chrome extension to look up the inventory levels or using the Stock Stats free plugin will help you do product tracking much quicker.”",
};
const KRYSTEL = {
  // JPG: JPG_KRYSTEL,
  JPG_2X: JPG_2X_KRYSTEL,
  url: "https://www.youtube.com/embed/60uUqVHlsiw?autoplay=1&modestbranding=1",
  name: "Krystel Abi Assi",
  position: "Amazon Middle East certified service provider and ad agency",
  text: "“The tools basically help you work smarter and not harder, because what each tool does is it gives you all the data in an easy-to-understand format and quickly, without you having to do things manually.”",
};
const RILEY = {
  // JPG: JPG_RILEY,
  JPG_2X: JPG_2X_RILEY,
  url: "https://www.youtube.com/embed/sTbZvUXieCg?autoplay=1&modestbranding=1",
  name: "Riley Turner",
  position: "Entrepreneur mentor & 6-figure Amazon FBA seller",
  text: "“You can get all the tools you need for product research all in one place, on one website, from one company at a low price. And that company will be AMZScout.”",
};
const PAUL = {
  // JPG: JPG_PAUL,
  JPG_2X: JPG_2X_PAUL,
  url: "https://www.youtube.com/embed/imI8U4JU03s?autoplay=1&modestbranding=1",
  name: "Paul J Lipsky",
  position: "86,000 subscribers",
  text: "Seller, Coach & YouTuber",
};
const TANNER = {
  // JPG: JPG_TANNER,
  JPG_2X: JPG_2X_TANNER,
  url: "https://www.youtube.com/embed/JCjblGLDFlk?autoplay=1&modestbranding=1",
  name: "Tanner J Fox",
  position: "142,000 subscribers",
  text: "7-figure Amazon Seller & Entrepreneur",
};

const ANDY = {
  // JPG: JPG_ANDY,
  JPG_2X: JPG_2X_ANDY,
  url: "https://www.youtube.com/embed/Ld1KHjg5fB4?autoplay=1&modestbranding=1",
  name: "Andy Amazon Advocate",
  position: "4,300 subscribers",
  text: "Seller, Trainer & Speaker",
};
const CRISTIAN = {
  // JPG: JPG_CRISTIAN,
  JPG_2X: JPG_2X_CRISTIAN,
  url: "https://www.youtube.com/embed/vJQfVHdtM5U?autoplay=1&modestbranding=1",
  name: "Cristian Tito",
  position: "14,800 subscribers",
  text: "Entrepreneur & YouTuber",
};
const RECO_2 = {
  // JPG: JPG_RECO,
  JPG_2X: JPG_2X_RECO,
  url: "https://www.youtube.com/embed/UhZ8s3zgZnM?autoplay=1&modestbranding=1",
  name: "Reco Jefferson",
  position: "2,000 subscribers",
  text: "Seller, Trainer & YouTuber",
};
const CARLOS = {
  // JPG: JPG_CARLOS,
  JPG_2X: JPG_2X_CARLOS,
  url: "https://www.youtube.com/embed/HaiQPFyUP-o?autoplay=1&modestbranding=1",
  name: "Carlos Fierro",
  position: " ",
  text: "Entrepreneur & Amazon FBA Seller",
};
const EVEN = {
  // JPG: JPG_EVEN,
  JPG_2X: JPG_2X_EVEN,
  url: "https://www.youtube.com/embed/IkQ_hI50gC0?autoplay=1&modestbranding=1",
  name: "Vova Even",
  position: "1,400 subscribers",
  text: "Amazon seller & YouTube blogger",
};
const ANDREW = {
  // JPG: JPG_ANDREW,
  JPG_2X: JPG_2X_ANDREW,
  url: "https://www.youtube.com/embed/jnVPmjEozMs?autoplay=1&modestbranding=1",
  name: "Andrew Giorgi",
  position: "2,200 subscribers",
  text: "Seller, Trainer & Blogger",
};
const SAMI = {
  // JPG: JPG_SAMI,
  JPG_2X: JPG_2X_SAMI,
  url: "https://www.youtube.com/embed/XgMl2pzEckI?autoplay=1&modestbranding=1",
  name: "Sami \"Big Digital Fish\"",
  position: " ",
  text: "Amazon Trainer & Speaker",
};

export const TESTIMONIALS_VIDEOS_LIST_CONTENT = [
  TRAVIS,
  STEVE,
  DAN,
  WILLIAM,
  LUKE,
  RECO,
  KELLY,
  JAMIE,
  RYAN,
  CRESCENT,
  KRYSTEL,
  RILEY,
];

export const TESTIMONIALS_VIDEOS_LIST_PG_AP_CONTENT = [
  PAUL,
  TANNER,
  ANDY,
  CRISTIAN,
  RECO_2,
  CARLOS,
  EVEN,
  ANDREW,
  SAMI,
];
